import React, { useEffect, useRef, useState } from "react";
import { Button, Tabs, Tooltip } from "antd";
import Permbledhje from "../Permbledhje/Permbledhje";
import ListaEkandidateve from "../Kandidatet/ListaEkandidateve";
import Kanban from "../Kanban/Kanban";
import "./MainTabs.scss";
import { IntervistatIconGreen } from "../../../assets/icons";
import KanbanFilters from "../Kanban/KanbanFilters";
import FilterComponent from "./FilterComponent";
import { CreatePage, ShowInfoPage } from "../Kandidatet/ShtoKandidat";
import moment from "moment";
import { API } from "aws-amplify";
import { driveApi } from "../../DriveRequest";
import { useDispatch, useSelector } from "react-redux";
import { useUploadedFiles } from "../../Documentation/hooks/useUploadedFiles";
import { InterviewsContext } from "../data";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { htmlParser } from "../../../utils";
import { PdfWhiteIcon } from "../../Documentation/View/assets";
import InterviewExcelExporter from "./InterviewExcelExporter";
import { candidates as candidatesReducer } from "../../../store/actions";
import ActivityModal from "../../punonjesit/EmployeeView/components/ActivityModal/ActivityModal";
import { sortObjectKeys } from "../../../utils/sortObjectKeys";

const MainTabs = () => {
  const { accessToken } = useSelector((state) => state.accessToken);
  const { candidates } = useSelector((state) => state.candidates);
  const { hotCredentials } = useSelector((state) => state.hotCredentials);
  // const [candidates, setCandidates] = useState([]);
  const driveRequest = driveApi({ accessToken });
  const location = useLocation();
  const dispatch = useDispatch();
  const { programFields } = useSelector((state) => state.programFields);
  const newInputs = programFields?.find(
    (el) => el?.fieldName === "Konfigurimi i Kandidateve"
  );

  const [activeKey, setActiveKey] = useState("1");
  const [openFilters, setOpenFilters] = useState(false);
  const [intervistat, setIntervistat] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    applicantInterviewDate: [moment(), moment().add(1, "years")],
  });
  const [excecute, setExcecute] = useState(false);
  const [kandidatiSelektuar, setKandidatiSelektuar] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  // Create User Page
  const [createUserPage, setCreateUserPage] = useState(false);
  // Confirm User Information Page
  const [showUserInformationPage, setShowUserInformationPage] = useState(false);
  // Temporary Array Data
  const [temporaryArray, setTemporaryArray] = useState(null);

  //show Shiko Ndryshimet modal
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [parentFolderId, setParentFolderId] = useState([]);
  const [tempUploadedFiles, setTempUploadedFiles] = useState([]);

  const [isChanged, setIsChanged] = useState(false);

  const [googleDriveFolderId, setGoogleDriveFolderId] = useState("");
  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const [
    uploadedFiles,
    setUploadedFiles,
    formattedDriveFiles,
    onPickerSuccess,
  ] = uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;

  const filterKeysOnInput = [
    "applicantFirstName",
    "applicantLastName",
    // "applicantStatus",
  ];
  //funciton to reset the props when the modal is closed
  const resetGoogleDriveProps = () => {
    setUploadedFiles([]);
    setFilesToBeDeleted([]);
    setTempUploadedFiles([]);
    setIsChanged(false);
  };

  const colors = {
    "Listuar për intervistë": "#FCC94A",
    "Në proçes": "#FCC94A",
    Pranuar: "#20AC9D",
    Skualifikuar: "#EA3943",
    Draft: "#787677",
    Trial: "#FCC94A",
    "Nuk kaloi trial": "#FCC94A",
    "Nuk skeduloi intervistë": "#20AC9D",
    Test: "#EA3943",
    "Nuk kaloi test": "#787677",
    Draft: "#787677",
  };

  const defaultSwalProperties = {
    toast: true,
    timer: 3000,
    showConfirmButton: false,
    position: "bottom-end",
  };

  const onChange = (value, key) => {
    let filters = {};
    if (value) {
      filters = { ...activeFilters, [key]: value };
    } else {
      filters = { ...activeFilters };
      delete filters[key];
    }
    setActiveFilters(filters);
  };

  const tabs = [
    {
      key: "1",
      label: "Përmbledhje",
      children: <Permbledhje />,
      destroyInactiveTabPane: true,
    },
    {
      key: "2",
      label: "Lista e kandidatëve",
      children: <ListaEkandidateve />,
      destroyInactiveTabPane: true,
    },
    {
      key: "3",
      label: "Kanban",
      children: <Kanban />,
      destroyInactiveTabPane: true,
    },
  ];

  // useEffect i cili vendos parent folder ne onedrive
  useEffect(() => {
    if (!!hotCredentials?.length) {
      const parentFolder = hotCredentials.find(
        (el) => el?.credentialId === "8"
      );
      setParentFolderId(parentFolder.credentialValue);
    }
  }, [hotCredentials]);

  const getCandidatesOnDB = async () => {
    await API.get("interviews", `/interviews`)
      .then((res) => {
        // setCandidates(res);
        dispatch(candidatesReducer(res));
        console.log("candidatesfromDB", res);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getCandidatesOnDB();
  }, []);

  // useEffect i cili permbledh te gjitha kandidatet dhe i ben filter sipas filters
  useEffect(() => {
    if (!candidates?.length || !Object.keys(activeFilters)?.length) {
      setIntervistat(candidates);
      return;
    }

    const filterFunction = (intervista) => {
      return Object.keys(activeFilters).every((key) => {
        if (key === "kandidati") {
          return filterKeysOnInput?.some((nameKey) =>
            intervista?.[nameKey]
              ?.toLowerCase()
              ?.includes(activeFilters?.[key]?.toLowerCase())
          );
        } else if (key === "applicantInterviewDate") {
          return Array.isArray(intervista?.[key])
            ? intervista?.[key]?.includes(activeFilters?.[key])
            : moment(activeFilters?.[key][0]).utc().format("YYYY-MM-DD") <=
                moment(intervista?.[key]).utc().format("YYYY-MM-DD") &&
                moment(intervista?.[key]).utc().format("YYYY-MM-DD") <=
                  moment(activeFilters?.[key][1]).utc().format("YYYY-MM-DD");
        } else {
          return Array.isArray(intervista?.[key])
            ? intervista?.[key]?.includes(activeFilters?.[key])
            : intervista?.[key] === activeFilters?.[key];
        }
      });
    };

    const newIntervistat = candidates?.filter(filterFunction);
    setIntervistat(newIntervistat);

    if (!!excecute) {
      setTimeout(() => {
        setExcecute(false);
      }, 500);
    }
  }, [activeFilters, candidates]);

  // useEffect i cili ben reset activeFilters edhe kandidatin e selektuar kur ndryshon tabi
  useEffect(() => {
    setKandidatiSelektuar({});
    window.history.replaceState({}, null);
  }, [activeKey]);

  //useEffect i cili shikon location state

  useEffect(() => {
    if (!!location?.state?.activeTab) {
      setActiveKey(`${location?.state?.activeTab}`);
    }
  }, [location?.state]);

  //translate keys of objects in array and sort asc based on them
  const replaceKeys = (array) => {
    let newData = [];
    array.forEach((el) => {
      let obj = {};
      for (const [key, value] of Object.entries(el)) {
        let newKey = newInputs?.fieldOptions.find(
          (item) => item.formItemName === key
        )?.label;
        obj = { ...(obj || {}), [newKey || key]: value };
      }
      const sortedObj = sortObjectKeys(obj, "asc");
      newData.push(sortedObj);
    });
    return newData;
  };

  //logic for exporting to PDF
  const exportPdf = async (data) => {
    const doc = new jsPDF({ orientation: "landscape" });
    data?.forEach((candidate, index) => {
      let yCoordinate = 15;
      let XCoordinate = 140;
      doc.setFontSize(20);
      let text = `${candidate.applicantFirstName} ${candidate.applicantLastName}`;
      doc.text(XCoordinate, yCoordinate, text);
      XCoordinate = 10;
      yCoordinate += 10;
      let formattedObj = {};
      for (const [key, value] of Object.entries(candidate)) {
        if (
          key !== "googleDriveFolderId" &&
          key !== "applicantId" &&
          key !== "googleDriveFiles" &&
          key !== "userId" &&
          key != "createdAt" &&
          key != "keylog" &&
          key != "applicantReApplied" &&
          key !== "applicantEventURI"
        ) {
          let formattedValue = `${
            !!value
              ? !!Array.isArray(value)
                ? value?.length > 0
                  ? value?.join(", ")
                  : "Nuk Ka"
                : key === "applicantStartDate" || key === "applicantBirthday"
                ? moment(value).format("DD/MM/YYYY")
                : key === "applicantInterviewDate"
                ? moment(value).format("DD/MM/YYYY HH:mm")
                : key === "applicantReference" || key === "applicantTraining"
                ? htmlParser(value).props.children
                : key === "applicantRoles"
                ? !!Array.isArray(value)
                  ? value?.join(", ")
                  : value
                : value
              : "Nuk Ka"
          }`;
          formattedObj = { ...(formattedObj || {}), [key]: formattedValue };
        }
      }
      const sortedObjects = replaceKeys([formattedObj]);
      sortedObjects?.forEach((obj) => {
        for (const [key, value] of Object.entries(obj)) {
          doc.setFontSize(13);
          doc.text(XCoordinate, yCoordinate, `${key}:`);
          doc.setFontSize(11);
          doc.text(XCoordinate + 70, yCoordinate, `${value}`);
          yCoordinate += 6;
        }
      });
      if (index !== data.length - 1) {
        doc.addPage();
      }
    });
    doc.save(
      data?.length > 1
        ? "Interviews_Table.pdf"
        : `${data[0]?.applicantFirstName}_${data[0]?.applicantLastName}_Table.pdf`
    );
  };
  console.log(kandidatiSelektuar);

  return (
    <InterviewsContext.Provider
      value={{
        isEditable,
        setIsEditable,
        createUserPage,
        setCreateUserPage,
        setTemporaryArray,
        kandidatiSelektuar,
        setShowUserInformationPage,
        temporaryArray,
        parentFolderId,
        setGoogleDriveFolderId,
        driveRequest,
        googleDriveFolderId,
        showUserInformationPage,
        setKandidatiSelektuar,
        intervistat,
        setIntervistat,
        onPickerSuccess,
        setTempUploadedFiles,
        tempUploadedFiles,
        uploadedFiles,
        formattedDriveFiles,
        onChange,
        activeFilters,
        setOpenFilters,
        openFilters,
        setActiveFilters,
        setExcecute,
        defaultSwalProperties,
        setUploadedFiles,
        resetGoogleDriveProps,
        onDelete,
        filesToBeDeleted,
        isChanged,
        setIsChanged,
        colors,
      }}
    >
      <div className="intervistatMainTabs">
        <div className="tabs">
          <Tabs
            className="intervistatTabs"
            defaultActiveKey={"1"}
            activeKey={activeKey}
            items={tabs}
            onChange={setActiveKey}
            destroyInactiveTabPane={true}
            tabBarExtraContent={
              <>
                {activeKey === "2" && (
                  <div className="buttons">
                    {(intervistat?.length > 0 || candidates.length > 0) && (
                      <InterviewExcelExporter
                        candidates={
                          Object.keys(kandidatiSelektuar).length > 0
                            ? [kandidatiSelektuar]
                            : candidates
                        }
                        newInputs={newInputs}
                      />
                    )}
                    {(intervistat?.length > 0 || candidates.length > 0) && (
                      <Tooltip
                        placement="top"
                        overlayClassName="global-icon-tooltip"
                        title={
                          Object.keys(kandidatiSelektuar).length > 0
                            ? `Eksporto "${kandidatiSelektuar?.applicantFirstName} ${kandidatiSelektuar?.applicantLastName}" në pdf`
                            : "Eksporto në pdf"
                        }
                        className="exportKandidateBtn"
                      >
                        <Button
                          icon={<PdfWhiteIcon height={20} width={18} />}
                          className="exportKandidateBtn"
                          style={{ backgroundColor: "#ea3943" }}
                          onClick={() => {
                            exportPdf(
                              Object.keys(kandidatiSelektuar).length > 0
                                ? [kandidatiSelektuar]
                                : candidates
                            );
                          }}
                        />
                      </Tooltip>
                    )}

                    {Object.keys(kandidatiSelektuar).length > 0 && (
                      <div className="applicantButtons">
                        <Button
                          className="shikoNdryshimetBtn"
                          onClick={() => setIsModalVisible(true)}
                        >
                          Shiko Ndryshimet
                        </Button>
                        <Button
                          className="ndryshoniBtn"
                          onClick={() => {
                            setIsEditable(true);
                            setCreateUserPage(true);
                          }}
                        >
                          Ndryshoni
                        </Button>
                      </div>
                    )}

                    {(intervistat?.length > 0 || candidates.length > 0) && (
                      <div
                        className="shtoKandidadBtnContainer"
                        onClick={() => {
                          setCreateUserPage(true);
                        }}
                      >
                        <Button className="shtoKandidadBtn">
                          Shtoni një kandidat
                        </Button>
                        <IntervistatIconGreen />
                      </div>
                    )}
                  </div>
                )}

                {activeKey === "3" && intervistat?.length !== 0 && (
                  <FilterComponent />
                )}
              </>
            }
          />
        </div>
        {isModalVisible && (
          <ActivityModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            showModal={() => setIsModalVisible(true)}
            keylog={kandidatiSelektuar?.keylog}
            name={`${kandidatiSelektuar?.applicantFirstName} ${kandidatiSelektuar?.applicantLastName}`}
          />
        )}
        {openFilters && <KanbanFilters />}
        {createUserPage ? (
          <CreatePage />
        ) : showUserInformationPage ? (
          <ShowInfoPage />
        ) : (
          ""
        )}
      </div>
    </InterviewsContext.Provider>
  );
};

export default MainTabs;
